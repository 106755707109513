<template>
  <div>
    <sidebar :id="id" :menu="menu" :submenu="submenu" />
    <v-container fluid>
      <template v-if="display">
        <LoadData :modify="modify" />
      </template>
      <template v-else>
        <v-alert dense outlined type="error"> Not Authorized ! </v-alert>
      </template>
    </v-container>
  </div>
</template>

<script>
import Sidebar from "../../components/Sidebar.vue";
import LoadData from "../../components/Transaksi/antarGudang/loadData.vue";
import { mapGetters } from "vuex";

export default {
  name: "Transaksi_Antar_Gudang",

  components: { Sidebar, LoadData },

  metaInfo: {
    title: "Rhea Admin",
    titleTemplate: "%s - Transaksi Antar Gudang",
    htmlAttrs: {
      lang: "id",
      amp: true,
    },
  },

  data: () => ({
    id: "1",
    menu: "Transaksi",
    submenu: " -> Antar Gudang",
    display: true,
    modify: true,
  }),

  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },

  created() {
    if (this.user.dataPermission.includes("surat_gudang.display")) {
      this.display = true;
    } else {
      this.display = false;
    }
    if (this.user.dataPermission.includes("surat_gudang.modify")) {
      this.modify = true;
    } else {
      this.modify = false;
    }
  },
};
</script>