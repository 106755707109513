<template>
  <div>
    <v-dialog v-model="inboundDialog" persistent max-width="80%">
      <v-card>
        <v-card-title class="text-h5 font-weight-bold">
          Terima Barang Antar Gudang
        </v-card-title>
        <v-divider class="mx-5"></v-divider>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col>
                <!-- Nomor Surat Jalan -->
                <v-card-title
                  class="pa-0 text-subtitle-1 font-weight-bold black--text"
                >
                  Nomor Surat Jalan
                </v-card-title>
                <v-text-field
                  outlined
                  dense
                  v-model="olahData.nomer_seri"
                  readonly
                  filled
                  hide-details
                ></v-text-field>
                <!-- Tanggal -->
                <v-card-title
                  class="pa-0 text-subtitle-1 font-weight-bold black--text"
                >
                  Tanggal
                </v-card-title>
                <v-text-field
                  v-model="olahData.tgl"
                  readonly
                  filled
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col>
                <!-- Gudang Asal -->
                <v-card-title
                  class="pa-0 text-subtitle-1 font-weight-bold black--text"
                >
                  Gudang Asal
                </v-card-title>
                <v-text-field
                  outlined
                  dense
                  v-model="olahData.gudang_asal_name"
                  readonly
                  filled
                  hide-details
                ></v-text-field>
                <!-- Gudang Tujuan -->
                <v-card-title
                  class="pa-0 text-subtitle-1 font-weight-bold black--text"
                >
                  Gudang Tujuan
                </v-card-title>
                <v-text-field
                  outlined
                  dense
                  v-model="olahData.gudang_penerima_name"
                  readonly
                  filled
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider class="mb-5"></v-divider>
            <v-row>
              <v-col cols="12">
                <v-card-title
                  class="pa-0 text-subtitle-1 font-weight-bold black--text"
                >
                  List Material
                </v-card-title>
              </v-col>
              <v-col cols="12">
                <v-data-table
                  v-model="olahData.selectMaterial"
                  :items="olahData.selectMaterial"
                  :headers="headersMaterial"
                  hide-default-footer
                  dense
                >
                  <template v-slot:item.total_diterima="{ item }">
                    <v-text-field
                      type="number"
                      outlined
                      dense
                      :rules="[rules.maxQty(item.total_diterima, item.total)]"
                      v-model="item.total_diterima"
                    ></v-text-field>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            color="#61B15A"
            style="text-transform: unset !important"
            @click="closeInbound"
          >
            Batal
          </v-btn>
          <v-btn
            color="#61B15A"
            class="white--text"
            style="text-transform: unset !important"
            @click="dataValidation"
            :disabled="!valid"
            :loading="loading"
          >
            Simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="popDialog" width="500">
      <v-card color="white" rounded>
        <v-card-title class="justify-center text-h6 black--text">
          {{ popTitle }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="light-green darken-1"
            class="white--text"
            @click="closePopDialog"
            >Tutup</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    inboundDialog: Boolean,
    olahData: Object,
  },
  data: () => ({
    valid: false,
    loading: false,
    popDialog: false,
    popTitle: "",
    list_summary: [],
    rules: {
      maxQty(value, total) {
        return (value || "") <= total || `Maximal ${total}`;
      },
    },
    headersMaterial: [
      {
        text: "Material",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "material_code",
      },
      {
        text: "Batch",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "batch_no",
      },
      {
        text: "Satuan",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "satuan",
      },
      {
        text: "Quantity",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "total",
      },
      {
        text: "Quantity Diterima",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "total_diterima",
      },
    ],
  }),
  methods: {
    dataValidation() {
      this.loading = true;
      var list = {};
      this.olahData.selectMaterial.forEach((v) => {
        list.batch_no = v.batch_no;
        list.type_box_id = v.type_box_id;
        list.total = v.total;
        list.total_diterima = v.total_diterima;
        this.list_summary.push(list);
        list = {};
      });
      const summ = {};
      summ.id = this.olahData.id;
      summ.list_summary = this.list_summary;
      const summSend = JSON.stringify(summ);
      console.log(summSend);
      axios
        .put("v1/admin/surat_jalan/summ", summSend, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(() => {
          this.loading = false;
          this.$emit("reloadDataInbound");
          this.closeInbound();
        });
    },
    closeInbound() {
      this.$refs.form.resetValidation();
      this.$emit("closeInboundDialog");
    },
    closePopDialog() {
      this.popTitle = "";
      this.popDialog = false;
    },
  },
};
</script>